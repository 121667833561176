import React, { useState,useEffect } from "react";
import Background from "../image/zone5.jpg";
import StallBg from "../image/zone8.jpg";
import ZOne1Brand from "../Branding/PARON Insurance Network/Paron IFA Branding Image jpeg.jpg";
// import Pdf from '../pdf2/ParonInsuranceNetwork/PARON COMPANY PROFILE 2022.pdf'
// import Pdf2 from '../pdf2/ParonInsuranceNetwork/ΠΑ Γυμναστών ΠΑΡΟΝ Flyer.pdf'
// import Pdf3 from '../pdf2/ParonInsuranceNetwork/ΠΑΡΟΝ North Η Ασφάλειά σας είναι η δουλειά μας Φυσικό Πρόσωπο.pdf'
// import Pdf4 from '../pdf2/ParonInsuranceNetwork/ΠΑΡΟΝ Η Ασφάλειά σας είναι η δουλειά μας Νομικό Πρόσωπο.pdf'
import HoverBrand from '../image/tran.png'

// import Pdf4 from '../pdf/greytHR - Brochure Material/Standee-03.pdf'
import axios from 'axios'


// import Pdf5 from '../pdf/Effitrac Brochures as PDF/Hrms _ Payroll brochure_India.pdf'

import Footer from "./footer";
import bcard from "../bcard/PARONInsuranceNetwork/f658deaeee3644f7abf96832bdbe2dbe-0001.jpg";



import html2canvas from 'html2canvas'


export default function Zone1() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [imgurl, setImgUrl] = useState();
  const [show, setShow] = useState(false);

  const [stateMenu, setstateMenu] = useState(false);
  //     const [useS,useSet]=useState(`<video
  //     loop
  //     autoPlay
  //     playsinline
  //     preload

  //     src="${Video1}"
  //     class="vide"
  //   />`)
  const [share, setShareData] = useState('')
  const [shareEmail, setShareEmail] = useState(true)
  const [stateMenuSurvey, setstateMenuSurvey] = useState(false);
  const [stateMenuWeb, setstateMenuWeb] = useState(false);
  
//   useEffect(async() => {
  
//     let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, {userEmail: localStorage.getItem('userEmail'),zoneView:"zone5",userName: localStorage.getItem('userName')})
  
//     let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
//  console.log("response",response,response2)
  
  
//   return () => {
  
//   };
//   },
  
//   []
//   );
  



  const[emailSend,setEmailSend]=useState("")
  const sendEmail =async (id) => {
  
   
  
  
    let response2 = await axios.post('https://sirc.virtuallive.in:3002/mailswnd',
    {userEmail: emailSend,
      userEmailBy:localStorage.getItem('userEmail'),
    userName: localStorage.getItem('userName'),
    userMemberid: localStorage.getItem('userMemberId'),
    userPhoneNumber:localStorage.getItem('userPhoneNumber')
  })
  console.log("response2${req.body.userEmail}${req.body.userEmail}",response2.data)
  
  if(response2.data.data=="done")
  {
    alert("your email has sent")
  setEmailSend("")
  }
  
  }


// 
// after 15000
const [dataScore2,setScoreData2]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/timespentrecord',{userEmail: localStorage.getItem('userEmail'),pageid:"zone5",time:5})
    

//   setScoreData2(dataScore2+1)
//   }, 5000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [dataScore2]
// )


// for last login 
const [lastlogin,setLastlogin]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
  

// setLastlogin(dataScore2+1)
//   }, 15000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [lastlogin]
// )




  // useEffect(() => {
  //   const script = document.createElement('script');
  
  //   script.src = 'https://embed.tawk.to/5f5221324704467e89ec296f/default';
  //   script.async = true;
  
  //   document.body.appendChild(script);
  
  //   return () => {
    
  //     document.body.removeChild(script);
  //   }
  // },[]);
  const ShareAsPicture = (id) => {
  
    var html = document.getElementsByTagName('HTML')[0]
    var body =  document.getElementsByTagName('BODY')[0]
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;
    
    var data = document.getElementById(`${id}`)
    var newWidth = data.scrollWidth - data.clientWidth
  
    
    if (newWidth > data.clientWidth){
        htmlWidth += newWidth 
        bodyWidth += newWidth
    }
    console.log("hhhss",htmlWidth,bodyWidth)
    html.style.width = htmlWidth + 'px'
    body.style.width = bodyWidth + 'px'
  
    
    html2canvas(data).then((canvas)=>{
        var image = canvas.toDataURL('image/png', 1);
      
       
        setShareEmail(false)
        setShareData(image)
       
        return image
        
  
    })
  }
  

    const exportAsPicture = (id) => {
        var html = document.getElementsByTagName('HTML')[0]
        var body =  document.getElementsByTagName('BODY')[0]
        var htmlWidth = html.clientWidth;
        var bodyWidth = body.clientWidth;
        
        var data = document.getElementById(`${id}`)
        var newWidth = data.scrollWidth - data.clientWidth

        
        if (newWidth > data.clientWidth){
            htmlWidth += newWidth 
            bodyWidth += newWidth
        }
        console.log("hhhss",htmlWidth,bodyWidth)
        html.style.width = htmlWidth + 'px'
        body.style.width = bodyWidth + 'px'

        
        html2canvas(data).then((canvas)=>{
            var image = canvas.toDataURL('image/png', 1);
            return image
            

        }).then((image)=>{
            saveAs(image, 'vis.png') 
            html.style.width = null
            body.style.width = null
        })
    }

    const saveAs = (blob, fileName) =>{
        var elem = window.document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        // elem.style = 'height:600px;width:600px';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
            elem.click();
        } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
    }

  return (
    <div
    style={{
      backgroundImage: `url(${StallBg})`,
      backgroundSize: "cover",
      height: "100vh",

      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    }}
  >
  
  {show &&<div className="w-40 absolute"> <A  /> </div>}
  <img

className=" absolute pointer  z-max"
src={HoverBrand}
alt=""
style={{
top:window.innerHeight >900 ? '29%':window.innerHeight > 700?'25%':'25%' ,
left:window.innerHeight >900 ? '28%':window.innerHeight > 700?'28%':'28%' ,
height:window.innerHeight >900 ? '180px':window.innerHeight > 700?'170px':'170px',
width:window.innerHeight >900 ? '350px':window.innerHeight > 700?'300px':'150px'}}
onMouseOver={() => setShow(true)}
onMouseOut={() => (setShow(false))}
/>
    <div
      className="flex w-100  items-end justify-end"
      style={{
       
        backgroundSize: "cover",
        height: "100vh",
        width:'100%',
        

        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'91%' } }
        >
         
          <Footer />
        </div> 


{  <div
          className=" absolute z-max  pointer"
          style={{ 
            top: window.innerHeight >900 ? '51%':window.innerHeight > 700?'48%':'48%' ,
            left:  window.innerHeight >900 ? '61%':window.innerHeight > 700?'60.5%':'60.5%' ,
            width: "400px",overflow: "scroll",height: "300px"}}
          // onClick={() => setShowProfile(true)}
        >
          {/* <img
            className="h2-3 w2-3"
            src={require("../src/image/prof.png").default}
            alt=""
          /> */}

          <nav className="nav ">
         
          </nav>
        </div>}


       

      <div>
       
        <iframe
          style={{
            position: "absolute",
            top: window.innerHeight >900 ? '-12%':window.innerHeight > 700?'-14%':'-14%' ,
            left:  window.innerHeight >900 ? '54%':window.innerHeight > 700?'58.5%':'58.5%' ,
            height: "100%",
            width: "22%",
          }}
          class=" "
          src="https://player.vimeo.com/video/694828234"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>{" "}
      
        <div
          style={{
            position: "absolute",
            top: "38.5%",
            left: "25.5%",
            width: "100%",
            // opacity:1,

            background: "",
            zIndex: 40,
            height: 20,
          }}
          onMouseOver={() => setstateMenu(false)}
          className=""
        ></div>
        <div
          style={{
            position: "absolute",
            top: "38.5%",
            left: "15.5%",
            width: "10%",
            opacity: 1,
            height: 104,

            background: "",
            zIndex: 3,
          }}
          onMouseOver={() => setstateMenu(false)}
          className=""
        ></div>
        <div
          style={{
            position: "absolute",
            top: "40.5%",
            left: "25.5%",

            zIndex: 3,
          }}
          className=""
        >
          {/* <div class="h-100 pt2 ph3 flex items-center flex-column justify-center pointer hover-bg-white-10 ">
            <img
              onMouseOver={() => setstateMenu(true)}
              // onMouseLeave={() => setstateMenu(false)}
              class="mr2 pointer pr1"
              height="22"
              width="80"
              src={require("../image/doc.gif").default}
              alt=""
            />
          </div> */}
        </div>
        {/* <div
          style={{
            position: "absolute",
            top: "49.5%",
            left: "25.5%",

            // width: "8%",
            zIndex: 3,
          }}
          className=""
          onMouseLeave={() => setstateMenu(false)}
        >
          {stateMenu && (
            <div
              className="flex bg-red   flex-column z-99999"
              style={{
                top: "16%",
                right: 0,
                position: "",
                width: "20rem",
              }}
            >
              <div className="pointer bg-red ma2" onClick={() => alert(8)}>
                12345678901234567892345678908sssssss765
              </div>
              <div
                className="pointer bg-pink ma2"
                onClick={() => alert("ontent")}
              >
                ontent 2
              </div>
              <div
                className="pointer bg-yellow ma2 "
                onClick={() => setShowPopUp(true)}
              >
                ontent 3
              </div>
            </div>
          )}
        </div> */}
      </div>
    

    </div>


      {/* opo uppp */}

      <div
        onMouseOut={() => setstateMenuSurvey(false)}
        onMouseOver={() => setstateMenuSurvey(true)}
        onClick={() => setShowPopUp(true)}
        className={`
          flex   items-center  justify-start absolute ${
            stateMenuSurvey ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "40%",
          right: 0,
          width: stateMenuSurvey ? "10%" : "4%",
        }}
      >
        <div className="flex pa1 ml2 "onClick={() => setShowPopUp(true)}>
          {" "}
          <img
            className="h2 w2"
            src={require("../image/Businesscard.png").default}
            alt=""
          />
        </div>
        {stateMenuSurvey && (
          <div onClick={() => setShowPopUp(true)} className=" ml1 f8">
            Business card
          </div>
        )}
      </div>




      <a
            href={"https://www.paron.com.gr/"}
            className="white"
            target="_blank"
          >
      {<div
        onMouseOut={() => setstateMenuWeb(false)}
        onMouseOver={() => setstateMenuWeb(true)}
       
        className={`
          flex   items-center  justify-start absolute ${
            stateMenuWeb ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "48%",
          right: 0,
          width: stateMenuWeb ? "10%" : "4%",
        }}
      >
        <div className="flex pa1 ml2 "onClick={() => setShowPopUp(true)}>
          {" "}
          <img
            className="h2 w2"
            src={require("../image/website.png").default}
            alt=""
          />
        </div>
        {stateMenuWeb && (
          <div onClick={() => setShowPopUp(true)} className=" ml1 f8">
           Website
          </div>
        )}
      </div>
      }
      </a>
      { showPopUp && (
            <div
            className="flex w-100 z-max   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-35   flex flex-column    justify-center items-center mh3 br3 overflow-hidden bg-white">
              <div
                onClick={() => setShowPopUp(false)}
                className=" pointer flex w-100 fw6 items-center justify-end f6 gray fw7 pa2 bg-navy "
              >
                X
              </div>

              <img   id="exportContainer1" src={bcard} alt="" />
             
              <div className="flex w-100  h2 flex items-center justify-center white bg-navy">
                {shareEmail ? (
                  <div
                    className="mh2  pointer white"
                    onClick={() => exportAsPicture("exportContainer1")}
                  >
                    Download
                  </div>
                ) : (
                  <div
                    className="mh2  pointer white"
                    onClick={() => setShareEmail(true)}
                  >
                    View Card
                  </div>
                )}

              
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

const A=(props)=> { return( <div className="flex h6 items-end justify-end w-100 bed z-max"> 
<div className="w-40 bg-yellow"><div className="flex justify-end items-end fixed absolute--fill z-9999 bg-black-20 w-40 h6 "
       style={{
          backdropFilter: 'blur(10px)' ,
       left:window.innerHeight >900 ? '20%':window.innerHeight > 700?'20%':'20%' ,
       top:window.innerHeight >900 ? '40%':window.innerHeight > 700?'40%':'30%' 
       }}>
       <img className="z-mx shadow-4  w-100" style={{ height:window.innerHeight >900 ? '450px':window.innerHeight > 700?'450px':'450px'}}src={ZOne1Brand} alt="" />

      </div></div>

</div>)
}