import React from 'react'

export default function expo() {
    return (
        <div>
            Expo
            
        </div>
    )
}
