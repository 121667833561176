import React,{useState,useEffect} from 'react'
import Imgd from './login.jpg'
import axios from 'axios'

export default function Login() {

  const [userEmail,setUserEmail]=useState("")
  const [userEmailErr,setUserEmailErr]=useState(false)

useEffect(() => {
  const script = document.createElement('script');

  script.src ='https://embed.tawk.to/60dacd3f65b7290ac63871e4/1f9bb3gu7';
  script.async = true;

  document.body.appendChild(script);

  return () => {
    
    document.body.removeChild(script);
  }
},[]);

  const hitApi=async()=>{

if(userEmail)
{

    const article = { 
      userEmail: userEmail,
     };
    axios.post('https://ifaacademy.virtuallive.in:3002/loginonetime', article)
        .then(response =>{
          console.log("setUserEmailErrsetUserEmailErrsetUserEmailErr",response.data)

          if(response.data!=="wrongLogin")
          {
        localStorage.setItem("userEmail",response.data.userEmail)
        localStorage.setItem("firstName",response.data.firstName)

        localStorage.setItem("lastName",response.data.lastName)
        window.location="/entry"

          }
          else{
setUserEmailErr(true)
          }


      
      }
        );


      
      }
      else{
        setUserEmailErr(true)
      }

  }

    const data="./login.jpg"
  return (
    <div  className="w-100 min-vh-100  flex  items-center justify-center"
  
    
    
    >
        <div className="w-100 min-vh-100">
<img className="w-100 min-vh-100" src={Imgd} alt="" />
</div>
<div  className="w-30  flex flex-column items-center justify-center absolute left-100" >

<div  className="flex w-100">
<div  className=" w-100 flex items-center justify-center mh1">
<input  className=" w-100 flex h2-3 br2" type="text" placeholder='First Name' />

</div>
<div  className="w-100 flex items-center justify-center mh1">
<input  className=" w-100 flex h2-3 br2" type="text" placeholder='Last Name' />


</div>

</div>

<div  className="flex w-100 mv3 br2">
<div  className=" w-100 flex items-center justify-center mh1">
<input  className=" w-100 flex h2-3  "
value={userEmail}
onChange={(e)=>(setUserEmailErr(false),setUserEmail(e.target.value.toLowerCase()))}
type="text" placeholder='Email' />

</div>
</div>
<div  className="flex w-100 mv3 br2">
<div  className=" w-100 flex items-start justify-start white mh1">
<input  className=" flex items-start justify-start   " type="checkbox" placeholder='Email' /> <div className='-nt1'> Remember me</div>

</div>
</div>


<div  className="flex w-100 mt1 br2">
<div  className=" w-100 flex flex-column items-center justify-center mh1">
<button onClick={()=>window.location.pathname="/entry"} className="flex w-30 pa2 ma2 white bg-blue items-center justify-center tc br2 ttu fw6 pointer">Login</button>
<p style={{color: 'red'}}>{userEmailErr && "Email id Not Found"}</p>

<button onClick={()=>window.location="/reg"} className="flex w-80 pa2 ma2 white bg-blue items-center justify-center tc br2 ttu fw6 pointer">if not registered ? click here</button>


</div>
</div>

</div>
    </div>
  )
}
