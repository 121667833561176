import React, { useEffect,useState } from "react";
import ZoneName from "../common/zoneName";
import Footer from "../common/footer";
import Back from '../image/back.jpg'
import axios from 'axios'
export default function Test() {



//   useEffect(async() => {
  
//      let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, {userEmail: localStorage.getItem('userEmail'),zoneView:"expohall",userName: localStorage.getItem('userName')})
 
//     let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
//  console.log("response",response,response2)
  
  
//   return () => {
  
//   };
// }
//   )

 

//   useEffect(() => {
//     const script = document.createElement("script");

//     script.src = "https://embed.tawk.to/5f5b4c924704467e89ee1498/default";
//     script.async = true;

//     document.body.appendChild(script);

//     return () => {
//         // alert(6)
//       document.body.removeChild(script);
//     };
//   }, []);


// 

// CPA Australia  
// Indus Novateur Softech Private Limited 
// Effitrac Solutions India Pvt Ltd
// Accopedia School of Accounting & Finance 
// Greytip Software Pvt. Ltd. 
// Karur Vysya Bank 
// Bajaj Allianz 
// Tamilnad Mercantile Bank 
// Kovai Medical Center and Hospital 
// SAIF Zone 
// Milky Mist  
// Zoho Corporation

// 
 
 
  return (
    <div className=" " style={{
      backgroundImage: `url(${Back})`,
      backgroundSize: "cover",
      height: "100vh",

      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    }}>
      {/* {console.log("sss", window.innerHeight)} */}
      {/* <div className="w-100 h-100">
        <img src={require("../image/back.jpg").default} class="bg" />
      </div> */}
      <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'92%' } }
        >
         
          <Footer />
        </div> 
      <ZoneName
        path="/zone1"
        height="7rem"
       
        width="13rem"
        fontSize="16px"
        name="CNP Cyprialife"
        top= {window.innerHeight >900 ? '69%':window.innerHeight > 700?'72%':'72%' }
        
      
        left= {window.innerHeight >900 ? '16%':window.innerHeight > 700?'16%':"14%" } 
      />



<ZoneName
        path="/zone2"
        height="7rem"
        width="13rem"
        fontSize="16px"
       
        name="Eurolife FFH"
        top= {window.innerHeight >900 ? '69%':window.innerHeight > 700?'72%':'72%' }
        left= {window.innerHeight >900 ? '36%':window.innerHeight > 700?'34%':"34%" } 
      />


<ZoneName
        path="/zone6"
        height="6rem"
        width="10rem"
        fontSize="16px"
       
        name="65 Χρόνια Πορείας με τους Συνεργάτες μας Πρωταγωνιστές"
        top= {window.innerHeight >900 ? '69%':window.innerHeight > 700?'55%':'55%' }
      
        left= {window.innerHeight >900 ? '55%':window.innerHeight > 700?'37%':"37%" } 
      />


<ZoneName
        path="/zone3"
        
        height="7rem"
        width="13rem"
        fontSize="16px"
        name="ΕΥΡΩΠΑΪΚΗ ΠΙΣΤΗ Α.Ε.Γ.Α"
        top= {window.innerHeight >900 ? '54%':window.innerHeight > 700?'72%':'72%' }
        left={window.innerHeight >900 ? '35%':window.innerHeight > 700?'52%':'52%' }
       
      />


{/* 2nd eow */}
<ZoneName
        path="/zone4"
        height="7rem"
        width="13rem"
        fontSize="16px"

        name="MEGA BROKERS - Partners for Life"
        top= {window.innerHeight >900 ? '69%':window.innerHeight > 700?'72%':'72%' }
        left="70%"
      />


<ZoneName
        path="/zone7"
        height={window.innerHeight < 700 ? "5rem" : "6rem"}
        width="11rem"
   
        fontSize="16px"
        left={window.innerHeight >900 ? '55%':window.innerHeight > 700?'53%':'52%' }
       
        name="IONIOS NEW AGENCY "
        top={window.innerHeight >900 ? '54%':window.innerHeight > 700?'55%':'55%' }
      />
{/* 2nd  */}

<ZoneName
        path="/zone5"
        height={window.innerHeight < 700 ? "5rem" : "6rem"}
        width="11rem"
        left="16%"
        fontSize="16px"
        name="ENGAGE XR "
      
        top={window.innerHeight >900 ? '54%':window.innerHeight > 700?'55%':'55%' }
      />     

<ZoneName
        path="/zone8"
        height={window.innerHeight < 700 ? "5rem" : "6rem"}
        width="11rem"
      

        left={window.innerHeight >900 ? '72%':window.innerHeight > 700?'72%':'70%' }
        fontSize="16px"
      
      
        name="PARON Insurance Network"
        top={window.innerHeight >900 ? '54%':window.innerHeight > 700?'55%':'55%' }
      />

{/* 2nd row */}


      {/* last row 1 IFA  */}
   <ZoneName
        path="/zone9"
        height={window.innerHeight < 700 ? "4rem" : "6rem"}
        width="8rem"
        // left="25%"
        left={window.innerHeight >900 ? '27%':window.innerHeight > 700?'26%':'25%' }
       
        fontSize="16px"
        name="Asia Insurance Review"
        top={window.innerHeight >900 ? '44%':window.innerHeight > 700?'42%':'45%' }
        // top={window.innerHeight < 700 ? "35%" : "23%"}
      />  
      <ZoneName
        path="/zone12"
        height={window.innerHeight < 700 ? "5rem" : "5rem"}
        width="9rem"
        left="67%"
        fontSize="16px"
        name="IFAACADEMY"
   
        top={window.innerHeight >900 ? '44%':window.innerHeight > 700?'44%':'42%' }
      />   
 <ZoneName
        height={window.innerHeight < 700 ? "4rem" : "6rem"}
        width="9rem"
        path="/zone10"
        // left="39%"
        left={window.innerHeight >900 ? '41%':window.innerHeight > 700?'39%':'39%' }
        fontSize="16px"
        name="GAMA GLOBAL"
        top={window.innerHeight >900 ? '44%':window.innerHeight > 700?'42%':'45%' }
      />
      {/* window.innerHeight */}
      <ZoneName
        path="/zone11"
        height={window.innerHeight < 700 ? "4rem" : "6rem"}
        width="8rem"
        left={window.innerHeight >900 ? '54%':window.innerHeight > 700?'54%':'53%' }
        

        fontSize="16px"
        name="LIFE HAPPENS"
        top={window.innerHeight >900 ? '44%':window.innerHeight > 700?'42%':'45%' }
        // left="53%"
      />

     
    

      {/* 2nd row */}
     
   
     
   

      {/* bottom zone */}
     
     
    
      
    </div>
  );
}
