import React, { useState, useEffect } from 'react'
import Res from './reg.jpg'

import VideoPop from "../common/videoPop";
import Bcard from "../image/bcard.jpg";
import V1thum from '../image/rs1.jpeg'
import V2thum from '../image/rs2.jpeg'

import V221thum from '../image/v221.jpeg'
import V222thum from '../image/v222.jpeg'

import Img1 from '../image/E1.png'
import Img2 from '../image/E3.png'

import Img3 from '../image/E4.png'

import Footer from '../common/footer';
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import V3thum from '../image/rs3.jpeg'

// import V2thum from '../image/v102.jpeg'
// import Resourcecenter from './resourcecenter';
export default function Resourcecenter() {
  const [videoPop, setVideoPop] = useState(false);
  const [videoPop2, setVideoPop2] = useState(false);
  const [smileyHeart,setsmilSyHeart]=useState(0)
  const [smileyClap,setSmileyClap]=useState(0)
  const [hitlike,setHitLike]=useState(localStorage.getItem('hitlike'))


  const [smileythumb,setSmileythumb]=useState(0)
  useEffect(()=>{
    let article = { 
      smileyname: "smileythumb",
      userEmail:localStorage.getItem('userEmail'),
  
    }
 
  
    axios.post('https://ifaacademy.virtuallive.in:3002/likescounttell', article)
    .then(response => {
      console.log("responseresponseresponseresponse",response.data);
  
      setSmileythumb(response.data.smileythumb)
      setsmilSyHeart(response.data.smileyHeart)
      setSmileyClap(response.data.smileyClap)
  
   
      
      
    }
  
    )
  }

  )
const Hitapi=(smileyname)=>{
  let article = { 
    smileyname: smileyname,
    userEmail:localStorage.getItem('userEmail'),

  }


  axios.post('https://ifaacademy.virtuallive.in:3002/likescounthit', article)
      .then(response => {
        console.log("responseresponseresponseresponse",response.data);

        setSmileythumb(response.data.smileythumb)
        setsmilSyHeart(response.data.smileyHeart)
        setSmileyClap(response.data.smileyClap)
  
       
        
        
      }
      )
    }
    





  // useEffect(async () => {

  //   let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, { userEmail: localStorage.getItem('userEmail'), zoneView: "resource", userName: localStorage.getItem('userName') })

  //   let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
  //     {
  //       userEmail: localStorage.getItem('userEmail'),
  //       userName: localStorage.getItem('userName'),
  //       userMemberid: localStorage.getItem('userMemberId'),
  //       userPhoneNumber: localStorage.getItem('userPhoneNumber')
  //     })
  //   console.log("response", response, response2)


  //   return () => {

  //   };
  // }
  // )

  // 
  // after 15000
  const [dataScore2, setScoreData2] = useState(0)
  // useEffect(async () => {
  //   let timer1 = setTimeout(async () => {
  //     let response = await axios.post('https://sirc.virtuallive.in:3002/timespentrecord', { userEmail: localStorage.getItem('userEmail'), pageid: "resource", time: 5 })

  //     //  alert(8)
  //     setScoreData2(dataScore2 + 1)
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timer1);
  //   };
  // },

  //   [dataScore2]
  // )


  // for last login 
  const [lastlogin, setLastlogin] = useState(0)
  // useEffect(async () => {
  //   let timer1 = setTimeout(async () => {
  //     let response = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
  //       {
  //         userEmail: localStorage.getItem('userEmail'),
  //         userName: localStorage.getItem('userName'),
  //         userMemberid: localStorage.getItem('userMemberId'),
  //         userPhoneNumber: localStorage.getItem('userPhoneNumber')
  //       })

  //     //  alert(8)
  //     setLastlogin(dataScore2 + 1)
  //   }, 15000);

  //   return () => {
  //     clearTimeout(timer1);
  //   };
  // },

  //   [lastlogin]
  // )

  const data="./reg.jpg"

  const myStyle = {
    backgroundImage:`url(${require(`${data}`)})`,
    height: "100vh",

  
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "center"
  };
  return (
    <div
  >

      <div
        className="flex items-center  justify-center w-100"
        style={{ 
          position: "absolute",
           top: window.innerHeight > 900 ? '89%' : window.innerHeight > 700 ? '98.5%' : '98%' }}
      >

        <Footer color={true} />
      </div>
      <div className='flex  w-100 '>
        <div className='flex w-75   ' >
          <iframe 
          src="https://vimeo.com/event/2001996/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
            style={{
              marginTop: window.innerHeight > 900 ? '-120px' : window.innerHeight > 700 ? '-15px' : '-15px' ,
            width: '100%',
              height: '100vh'
            }}></iframe>

        </div>
        <div className='flex flex-column w-25  '>


          <iframe src="https://vimeo.com/event/2001996/chat/"
           width="100%" height="100%" frameborder="0"></iframe>
          <div className='flex w-100 h4 items-center justify-around'>
            <div className='flex flex-column items-center justify-center'>
              <img className='mh1 pointer flex flex-column items-center justify-center' style={{ height: '55px' }}
              onClick={()=>Hitapi('smileyClap')}
               src={Img1} />
              <p className='mt2 f6'>{smileyClap}</p>
            </div>
            <div className='flex flex-column items-center justify-center'>
              <img
               onClick={()=>Hitapi('smileyHeart')}
               className='mh1 pointer flex flex-column items-center justify-center' style={{ height: '50px' }} src={Img2} />
              <p className='mt2 f6'>{smileyHeart}</p>
            </div>
            <div className='flex flex-column items-center justify-center'>
              <img 
               onClick={()=>Hitapi('smileythumb')}
              className='mh1 flex pointer flex-column items-center justify-center' style={{ height: '50px' }} src={Img3} />
              <p className='mt2 f6'>{smileythumb}</p>
            </div>








          </div>


        </div>
      </div>









    </div>
  )
}
