import React,{useState,useEffect} from 'react'
import Res from '../image/resource-center.jpg'

 import VideoPop from "../common/videoPop";
import Bcard from "../image/bcard.jpg";
import V1thum from '../image/rs1.jpeg'
import V2thum from '../image/rs2.jpeg'

import V221thum from '../image/v221.jpeg'
import V222thum from '../image/v222.jpeg'


import Footer from '../common/footer';
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import V3thum from '../image/rs3.jpeg'

// import V2thum from '../image/v102.jpeg'
// import Resourcecenter from './resourcecenter';
export default function Resourcecenter() {
  const [videoPop, setVideoPop] = useState(false);
  const [videoPop2, setVideoPop2] = useState(false);

 





// useEffect(async() => {
  
//   let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, {userEmail: localStorage.getItem('userEmail'),zoneView:"resource",userName: localStorage.getItem('userName')})

//  let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//  {userEmail: localStorage.getItem('userEmail'),
//  userName: localStorage.getItem('userName'),
//  userMemberid: localStorage.getItem('userMemberId'),
//  userPhoneNumber:localStorage.getItem('userPhoneNumber')
// })
// console.log("response",response,response2)


// return () => {

// };
// }
// )

// 
// after 15000
const [dataScore2,setScoreData2]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/timespentrecord',{userEmail: localStorage.getItem('userEmail'),pageid:"resource",time:5})
    
// //  alert(8)
//   setScoreData2(dataScore2+1)
//   }, 5000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [dataScore2]
// )


// for last login 
const [lastlogin,setLastlogin]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
  
// //  alert(8)
// setLastlogin(dataScore2+1)
//   }, 15000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [lastlogin]
// )



    return (
        <div
        style={{
          backgroundImage: `url(${Res})`,
          backgroundSize: "cover",
          height: "100vh",
    
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}>
               <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'88%' } }
        >
         
          <Footer />
        </div> 
               {
          <div
            className=" absolute z-999  pointer"
            style={{
                top:window.innerHeight >900 ? '56%':window.innerHeight > 700?'53%':'52%',
                left:window.innerHeight >900 ? '55%':window.innerHeight > 700?'20%':'20%',
            }}
            // onClick={() => setShowProfile(true)}
          >
            {/* <img
            className="h2-3 w2-3"
            src={require("../src/image/prof.png").default}
            alt=""
          /> */}

            <nav className="nav ">
              <ul className="nav__menu tc" style={{ background: "transparent" }}>
                <li className=" nav__menu-item  tc">
                  <a className="tc">
                    
                    <img
                      className="h3 w3"
                      // src={require("../image/prof.png").default}
                      src={require("../image/doc.gif").default}
                      alt=""
                    />
                {/* <b className="tc">Resource Materials</b>   */}
                  </a>
           
                </li>
              </ul>
            </nav>
          </div>
        }
          {videoPop && (
        <VideoPop
        close={setVideoPop}
        videoUrl={'https://player.vimeo.com/video/645996325'} img={[
          {imgurl:V1thum,videurl:'https://player.vimeo.com/video/645996325',text:'CA K Raghu, Past President - ICAI'},
          {imgurl:V2thum,videurl:'https://player.vimeo.com/video/645996406',text:'CA R Bupathy, Past President - ICAI'},
          {imgurl:V3thum,videurl:'https://player.vimeo.com/video/645996463',text:'CA T N Manoharan, Past President - ICAI'}]}  />
        )}
           <div>
          <div 
          onClick={()=>setVideoPop(true)}
            className="tc"
            style={{
              position: "absolute",
             
              top:window.innerHeight >900 ? '56%':window.innerHeight > 700?'56%':'56%',
              left:window.innerHeight >900 ? '55%':window.innerHeight > 700?'45%':'44%',
              height: "10%",
              width: "12%",
            }}
          >
            <img
              className="pointer h3 w3"
              src={require("../image/play.gif").default}
              alt=""
            />
            {/* Click Here for video */}
          </div>




          <div 
        //   onClick={()=}
            className="tc"
            onClick={()=>setVideoPop2(true)}
            style={{
              position: "absolute",
             
              top:window.innerHeight >900 ? '56%':window.innerHeight > 700?'57%':'58%',
              left:window.innerHeight >900 ? '55%':window.innerHeight > 700?'72%':'72%',
              height: "10%",
              width: "12%",
            }}
          >
            <img
              className="pointer h3 w33"
              src={require("../image/play.gif").default}
              alt=""
            />
            {/* Click Here for video */}
          </div>
         
        </div>




        {videoPop2 && (
        <VideoPop
        close={setVideoPop2}
        videoUrl={'https://player.vimeo.com/video/645719637'} img={[
          {imgurl:V221thum,videurl:'https://player.vimeo.com/video/645719637',text:'SIRC OF ICAI DAY1 EVENT'},
          {imgurl:V222thum,videurl:'https://player.vimeo.com/video/647729918',text:'SIRC OF ICAI DAY2 EVENT'},
        ]}  />
        )}



        

        </div>
    )
}
