import React,{useState} from 'react'
import Bcard from "../image/bcard.jpg";

export default function VideoPop({videoUrl,img,close}) {
// const [videoUrl, setVideoUrl] = useState("https://player.vimeo.com/video/645476641")
const [stateVide, setStateVideo] = useState(videoUrl)
    return (
        
          <div
            className="flex w-100    justify-center shadow-4 items-center fixed absolute--fill z-max bg-black-20"
            style={{ backdropFilter: "blur(2px)", zIndex: "1000" }}
          >
            <div className=" w-80  flex flex-column    justify-center items-center mh3 br3 overflow-hidden bg-white">
              <div className="flex w-100 fw6 f6  items-center justify-start pa2 bg-navy black">
               <div className="w-100 "> </div>
             

               <div onClick={()=>(close(false))} className=" f6 gray fw6 pointer">X</div>
              </div>
              <div className="w-100   bg-gray  flex  flex   items-center justify-center ">
                <div className=" w-40    flex   items-center justify-start ">
                  <iframe
                    style={{
                      height: "255px",
                      width: "600px",
                    }}
                    src={stateVide}
                    frameborder="0"
                    // width="100"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className=" w-60 ">
                 
                  <div
                    className="w-100   flex flex-column items-start justify-start"
                    style={{ maxHeight: "200px", overflowY: "scroll" }}
                  >
                   
                   
                   {img.map((item,index)=>{return     (  <> 
                   {index==5&& <div className=" w-100 mv2 h1    flex  items-center justify-center  white  " style={{height:'100px',visibility:'hidden'}} >
                        <div className="flex  items-center justify-center  w-30">
                          <img src={Bcard} alt="" />
                        </div>
                        <div className="flex  items-center justify-center  w-60">
                         {/* video calll */}sss
                        </div>
                      </div>}

                     {<div onClick={()=>setStateVideo(item.videurl)} className="bb  b--silver  pointer w-100 mv2  flex  items-center justify-start   white  ">
                        <div className="flex  items-center justify-start    ml2">
                        <img    className="h3 w4"       src={item.imgurl} alt="" />
                        </div>
                        <div className="flex  b ml2 items-center f6 fw6 justify-start  w-60">
                         {item.text}
                        </div>
                      </div>
                   }
                      </>
  )}
  )}
                     
                  

                    
                  
                 
                </div>
                </div>
              </div>
            </div>
          </div>   
       
    )
}
