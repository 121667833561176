import React,{useState,useEffect} from 'react'
// import Auditorium from './auditorium';
// import Video1 from "../eexpo.mp4";
import { Link } from 'react-router-dom';
import Footer from '../common/footer';
import axios from 'axios';
export default function Auditorium() {
    const [stateMenu, setstateMenu] = useState(false);

  const [useS, useSet] = useState(`<video
    loop
    autoPlay
    playsinline
    preload
    

    class=${window.innerHeight >900 ? 'vide':window.innerHeight > 700?'videSmall':'videSmall' }
  />`);






//   useEffect(async() => {
  
//     // let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, {userEmail: localStorage.getItem('userEmail'),zoneView:"zone12",userName: localStorage.getItem('userName')})
 
//     let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
//  console.log("response",response2)
  
  
//   return () => {
  
//   };
// }
//   )

// window.location="/lobby"

  return (
    <div>
   
   <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:window.innerHeight >900 ? '90%':window.innerHeight > 700?'90%':'90%' } }
        >
         
          <Footer />
        </div> 
        <div className="w-100 h-100 min-vh-100">
        <iframe className="h-100 w-100 min-vh-100" src={`https://player.vimeo.com/video/696110098?autoplay=1&muted=0&controls=0}`}
        frameBorder='0'
        style={{height: window.innerHeight >900 ? '100vh':window.innerHeight > 880?'115vh':'125vh',
        marginTop: window.innerHeight > 900 ? '0px' : window.innerHeight > 880 ? '-80px' : '-80px' ,
        }}
        allow='autoplay; encrypted-media'
        allowFullScreen
        title='video'
/>
        <div className="f1"></div>
      </div>
    </div>
  );
}



