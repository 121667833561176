
import React,{useState,useEffect,useRef} from 'react';

import { Camera } from "../camrea";
import axios from 'axios'
import '../App.css';
import BackLobby from '../image/backlobby.png'
import { saveAsPng, saveAsJpeg } from 'save-html-as-image';
import domtoimage from 'dom-to-image'
import V1 from '../image/photo frame-01.png'
import V2 from '../image/photo frame-02.png'
import V3 from '../image/photo frame-03.png'

import Down from '../image/down.png'
import V4 from '../image/photo frame-04.png'
// import Footer from './footer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
 const  CamreaApp=()=> {
  const myRef = useRef(null);
 const  toScroll=()=>{
  window.scrollTo(100,window.innerHeight);
 }



// useEffect(async() => {
  
//   let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, {userEmail: localStorage.getItem('userEmail'),zoneView:"photobooth",userName: localStorage.getItem('userName')})

//  let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//  {userEmail: localStorage.getItem('userEmail'),
//  userName: localStorage.getItem('userName'),
//  userMemberid: localStorage.getItem('userMemberId'),
//  userPhoneNumber:localStorage.getItem('userPhoneNumber')
// })
// console.log("response",response,response2)


// return () => {

// };
// }
// )
const [dataScore2,setScoreData2]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/timespentrecord',{userEmail: localStorage.getItem('userEmail'),pageid:"photo",time:5})
    
// //  alert(8)
//   setScoreData2(dataScore2+1)
//   }, 5000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [dataScore2]
// )


// for last login 
const [lastlogin,setLastlogin]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
  
// //  alert(8)
// setLastlogin(dataScore2+1)
//   }, 15000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [lastlogin]
// )










   const [showDiv,setShowDiv]=useState(false)
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState(false);
    const  filter= ["inline-svg","bordered2","sketchy","shubharam","headshot-3","bordernew","colorBorder","nfff"]
    const [clas,Setfil]=useState("real222")
    const [test2,SetTest]=useState("")
    const [totalwords,setTotal]=useState("")
    const [result,setresult]=useState("")
    const [result2,setresult2]=useState("")
    const [download,setDownload]=useState("")
    const [download2,setDownload2]=useState("")
    const [download3,setDownload3]=useState("")
  
  
    const dd=()=>{
     
      const node = document.getElementById('parent');
   
      saveAsPng(node);
  
    }
    const dd1=()=>{
     
      const node = document.getElementById('parent2');
   
      saveAsPng(node);
  
    }
    const dd2=()=>{
     
      const node = document.getElementById('parent3');
   
      saveAsPng(node);
  
    }
    const printBarcode = () => {
  
     setTimeout(() => {
       
    
      domtoimage.toBlob(document.getElementById('parent'))
      .then(function(blob) {
      
        setresult2( URL.createObjectURL(blob))
      
        let reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            var base64data = reader.result;                
            console.log(base64data);
            setDownload(base64data)
           
        }
      })
  
  
      domtoimage.toBlob(document.getElementById('parent2'))
      .then(function(blob) {
      
        setresult2( URL.createObjectURL(blob))
      
        let reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            var base64data2 = reader.result;                
         console.log("base64data2base64data2",base64data2)
            setDownload2(base64data2)
            // axios.post(`http://localhost:4000/data`, {base64data} )
            // .then(res => {
            //   console.log(2222)
            //   console.log(res);
            //   console.log(res.data);
            // })
        }
      })
    
      domtoimage.toBlob(document.getElementById('parent3'))
      .then(function(blob) {
      
        setresult2( URL.createObjectURL(blob))
      
        let reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            var base64data3 = reader.result;                
           
            setDownload3(base64data3)
            // axios.post(`http://localhost:4000/data`, {base64data} )
            // .then(res => {
            //   console.log(2222)
            //   console.log(res);
            //   console.log(res.data);
            // })
        }
      })
      
         }, 100);
        }
  
  const handleCap=(blob)=>{
   
    setCardImage(blob)
    // printBarcode()
  }
  
      
    return (
      <div className="flex w-100 ">
  
 
<Link to="/lobby">
        <div className="absolute z-max flex  items-center jsutify-center flex-column " style={{top:'15%',left:'2%'}}> 
       <div className="flex" >  <img className="h2-3 w2-3" src={BackLobby} alt="" /></div>
       <div className="ttu f8 mt1 fw6 white">back to Lobby</div>
       </div>
     
       </Link>
              <div class="flex flex-column w-100 items-center justify-center   ">
  
            
            
                  <div class="flex  w-100 items-center justify-center backimgphoto ">
                     
                      <div class="flex flex-column  w-100 items-center justify-center  " >
                      <div class="flex  w-100 items-center justify-center ">
                              <div class="flex w-100 items-center justify-center ">
                                  <div class="flex w-100 items-center justify-center">
                                      <div class="  flex w-100 items-center justify-center">
                                      {isCameraOpen &&<Camera 
                                      setShowDiv={setShowDiv}
                                      showDiv={showDiv}


              onCapture={blob => handleCap(blob)}
              onClear={() => setCardImage(undefined)}
                                      /> }  </div>
                                 
                                  
                                  </div>
                                  
                                  {!isCameraOpen &&    <div class="flex  w-100 items-center justify-center" style={{visibility:"hidden"}} >
                                      <h1 class="display-2 mb-3">Get in CLICKED today</h1>
                                      <p class="lead">Welcome to world of awseomeness with Shubharambh Productions.</p>
                                    
                                  </div>}
                              </div>
                              
                          </div>
      <div class="flex w-100 items-center justify-center ">
                                <div className="flex w-10 items-center justify-center">     <button style={{margin:"10px"}} type="submit" 
                               onClick={() => setIsCameraOpen(true)} class="bg-white pa2 br2">Open Camera </button></div>    
                         <div className="flex w-10 items-center justify-center"><button style={{margin:"10px"}} onClick={() => {
                setIsCameraOpen(false);
                setCardImage(undefined);
                setShowDiv(false)
              }}  type="submit" class="bg-white pa2 br2">Close Camera</button></div>
                              
                          </div>  
                                                
                      </div>
                     
                  </div>
  
                

                  {/* <Footer /> */}

                  {/* <div class="row "  >
                  <div class="col-md-5 col-lg-5" className="unfill" >
                  <img src={V1} className="" alt=""  /> 
                  </div>
                  <div class="col-md-5 col-lg-5"  className="unfill">
                  <img src={V2} className="" alt="" /> 
                  </div>
                  <div class="col-md-5 col-lg-5"  className="unfill">
                  <img src={V3} className="" alt="" /> 
                  </div>
                  <div class="col-md-5 col-lg-5" className="unfill" >
                  <img src={V3} className="" alt="" /> 
                  </div>

                  </div> */}


<div className=" flex flex-column w-100 items-center justify-center " style={{ background:'#000000'}}  > 
<div className="white">SCROLL DOWN TO DOWNLOAD FRAME</div>
 <img  
 onClick={()=>toScroll()}

style={{  transform: "rotate(270deg)",background:'#000000'}}
className="h2-3 w2-3" src={BackLobby} alt="" /></div>
                 { !showDiv && <div class="flex w-100 items-center justify-center  backimgphoto2" >
                     <div className="flex flex-column  w-100 items-center justify-center">
                                        <div class="flex flex-column  w-90 items-center justify-center"  >
                  <div id="parent" className="paren">
  <img src={V1} className="over-im imgp" alt="" /> 
 

  </div>
  


                  </div>
               
                  <div class="flex flex-column  w-90 items-center justify-center" >
                  <div id="parent" className="parent">
  <img src={V2} className="over-im imgp" alt="" /> 
 

  </div>
  


                  </div>

                  </div>  

                <div className="flex flex-column  w-100 items-center justify-center">
                                        <div class="flex flex-column  w-90 items-center justify-center"  >
                  <div id="parent" className="parent">
  <img src={V3} className="over-im imgp" alt="" /> 
 

  </div>
  


                  </div>
               
  

                  <div class="flex flex-column  w-90 items-center justify-center"  >
                  <div id="parent" className="parent">
  <img src={V4} className="over-im imgp" alt="" /> 
 

  </div>
  


                  </div>
                  </div>

  
                  </div>
                 }


                 
                 {showDiv && <div className="flex w-100 items-center justify-center backimgphoto2 "  ref={myRef} >

                  
                   
                  { cardImage &&    <div className="flex flex-column w-100 items-center justify-center">
                      <div className="flex mv2 flex w-100 items-center justify-center">
                  <div class="flex flex-column w-80 items-center justify-center" >
                  <div id="parent" className="parent">
  <img src={V1} className="over-img imgp" alt="" onClick={()=>dd1()}/> 
  <img className="imgp" src={cardImage && URL.createObjectURL(cardImage)} />
  {/* <button download>DOnwload</button>  AIzaSyBkHhQsmHQsJ6HySOWwMUJRu5F0ZJ-FZyE*/}
  </div>
  <div className="w-100 flex flex-column items-center justify-center">  <img className="down" onClick={()=>dd()}  style={{height:"25px"}} src={Down} /> 
  <p className="menuname" onClick={()=>dd()}>Click To Download</p></div>

  {/* <MyComponent  data={download} /> */}
                  </div>
                  <div class="flex flex-column w-80 items-center justify-center" >
              
  <div id="parent2" className="parent ">
  <img  src={V2} className="over-img imgp" alt="" onClick={()=>dd1()} /> 
  <img   className="imgp"  src={cardImage && URL.createObjectURL(cardImage)} />
  
  
  </div>
<div className="w-100 flex flex-column items-center justify-center"> 
<img  className="down" onClick={()=>dd1()}  style={{height:"25px"}} src={Down} />

<p className="menuname" onClick={()=>dd()}>Click To Download</p></div>
  
 

  </div>
  </div>
  
  <div className="flex mv2  w-100 items-center justify-center">
  <div class="flex flex-column w-50  items-center justify-center" >
              
  <div id="parent3" className="parent">
  <img src={V3} className="over-img imgp" alt="" onClick={()=>dd1()} /> 
  <img className="imgp"  src={cardImage && URL.createObjectURL(cardImage)} />
  
  </div>
  <div className="w-100 flex flex-column  items-center justify-center"><img  className="down" onClick={()=>dd2()} style={{height:"25px"}} src={Down} /> 
<p className="menuname" onClick={()=>dd()}>Click To Download</p></div>                           

               
              </div>
  
              <div class="flex w-50 flex-column items-center justify-center" >
                  <div id="parent" className="parent">
  <img src={V4} className="over-img imgp" alt="" onClick={()=>dd1()} /> 
  <img className="imgp"  src={cardImage && URL.createObjectURL(cardImage)} />
  {/* <button download>DOnwload</button>  AIzaSyBkHhQsmHQsJ6HySOWwMUJRu5F0ZJ-FZyE*/}
  </div>
  <div className="w-100 flex flex-column  items-center justify-center"> <img  className="down" onClick={()=>dd()}  style={{height:"25px"}} src={Down} /> 

<p className="menuname" onClick={()=>dd()}>Click To Download</p></div>

                  </div>
  
  
              </div> 
  
                  </div>
                 }
  
                  </div>
                  }
                  <div className=" w-100 z-max flex flex-column justify-center items-center " style={{background:'#140F49',marginTop:'-5rem'}}> 
                  <Link to="/lobby">   <div className="flex justify-center items-center  " > 
        

        <img className="h2-3 w2-3" src={BackLobby} alt="" /></div>
       <div className="ttu f8 mt1 fw6 white tc">back to Lobby</div></Link>
       </div>
              </div>
            
             
          </div>
          
  
    
    )
  }
  
  
   
  class MyComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isModalOpen: false,
      };
    }
    
    openModal=() => this.setState({ isModalOpen: true });
    closeModal =()=> this.setState({ isModalOpen: false });
   
    render() {
      return (<>
     
      <button onClick={this.openModal} style={{marginTop:"5%",padding:'5px'}}   type="submit" class="btn btn-primary"><img style={{height:"25px"}} src={ V4} /> </button>
  
  
        
        </>
      );
    }
  }

  export default CamreaApp;

 


