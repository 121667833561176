import axios from 'axios';
import React,{useEffect,useState} from 'react'
// import Axios from ;axios
import BackLobby from '../image/backlobby.png'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function Liveaudition() {
    const [count, setCount] = useState(0);
    const [dataArr, setDataArr] = useState([]);




// after 15000
const [dataScore2,setScoreData2]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/timespentrecord',{userEmail: localStorage.getItem('userEmail'),pageid:"live",time:5})
    
// //  alert(8)
//   setScoreData2(dataScore2+1)
//   }, 5000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [dataScore2]
// )


// for last login 
const [lastlogin,setLastlogin]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
  
// //  alert(8)
// setLastlogin(dataScore2+1)
//   }, 15000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [lastlogin]
// )





    

    // useEffect(() => {
    //     const interval = setInterval(async() => {
    //     //  console.log('This will run every second!');
    //      let response = await axios.get('https://sirc.virtuallive.in:3002/lastlogindetail')
    //      if(  response&& response.data&&response.data&&response.data.total.length>0)
    //     { setDataArr(response.data.total)
    //     }
    //     // setCount(response.data.totaltill)
    //     }, 10000);
    //     return () => clearInterval(interval);
    //  }, [count]);


   

    //  useEffect(async() => {
    //     let response2 = await axios.get('https://sirc.virtuallive.in:3002/lastlogindetail')
    //     // console.log("ONESSS=>>>>>>>>>>>>>",response2.data.total)
    //     if(  response2&& response2.data&&response2.data&&response2.data.total.length>0)
    //  {  setDataArr(response2.data.total)}
    //     // setCount(response2.data.totaltill)
    //    console.log('after from useEffect!');
    //  }, []);
   
    
   console.log(count)
    
    return (
        <>
        <Link to="/lobby">
        <div className="absolute z-max flex  items-center jsutify-center flex-column " style={{top:'26%',left:'10%'}}> 
       <div className="flex">  <img className="h2-3 w2-3" src={BackLobby} alt="" /></div>
       <div className="ttu f8 mt1 fw6 white">back to Lobby</div>
       </div>
       </Link>
      <div
      className={`${window.innerHeight >900 ? 'liveattenBig':window.innerHeight > 700?'liveattenMedium':'liveatten'}  flex items-center justify-center`}
      // className=" liveatten flex items-center justify-center"
     
    >

    
          <div className="flex items-center ttu white justify-center flex-column w-70 mt5  " 
     >

            <div className="flex mt4 items-center justify-center w-90 ">
              <div className="flex  items-center justify-center  ba b--silver w-15 white fw6 ttu pa1 h2-3">Rank</div>

              <div className="flex  items-center justify-center ba b--silver  w-35  white fw6 ttu pa1 h2-3 ">Name</div>
              <div className="flex  items-center justify-center ba  b--silver w-35  white  fw6 ttu pa1 h2-3">Email</div>
              <div className="flex  items-center justify-center ba  b--silver w-15   white fw6 ttu pa1 h2-3">Status</div>
            </div>

<div className="w-100  flex flex-column items-center justify-start " style={{ overflowY: 'scroll',height:'500px'}}>
           {dataArr&& dataArr.map((item,index)=>{return(
           <div className="bl  br bb b--silver flex items-center justify-center h2-3 pa1 w-90 ">
              <div className="flex  items-center justify-center   w-15 white  lh-copy">{index+1}</div>

              <div className="flex  items-center justify-center  mh1 w-35  white lh-copy">{item.userName||'dummy3'}</div>
              <div className="flex  items-center justify-center   w-35 white  lh-copy">{item.userEmail}</div>
              <div className="flex  items-center justify-center  mh1 w-15 white   lh-copy"> {item.status=="true"?' online':'offline'} <div className={`br-100 ${item.status==="true"?'b--green bg-green':'b--red bg-red'} ml3   right-0   h1 w1 `}></div>  </div>
            </div>

           )}) 
            }
              

</div>

          </div>
          {/* <div className="absolute white fw6 f4 ttu" style={{top:'86%'}}> we  have {count} visitor till now and   {dataArr.length+1} live member with us</div>   */}
        </div>
        </>
       
    )
}
{/* <div className="flex w-100 items-center justify-center bg-green white">
{dataArr.length}
</div> */}