import React, { Component,useEffect,useState } from 'react'
import axios from 'axios'
import api from "../image/apifront.png";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import BackLobby from '../image/backlobby.png'

import Rsgif from '../image/rsgif.gif'
// leaders-boardpoint.jpg
class apicall22 extends Component {
  state={
    data:{}
  }
    
         req = async () => {
            const response = await axios.get('https://dog.ceo/api/breeds/list/all')
            if(response.status==200)
            {
              
                this.setState({data:'s'})
            }
          }
    
    componentDidMount() {
        this.interval = setInterval(() => this.req(), 3000);
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }
    render() {
        return (
            <div>
                ssssss
            </div>
        )
    }
}




export default  function Apicall(props) {
  const [agendaShow, setAgendaShow] = useState(false);

  const location = useLocation();
  console.log("11==>>>>>>>>>",parseInt(localStorage.getItem('userMemberId')))
  // console.log('props====>>>>>>>>>>>>>>>>>>',props)
    const [rom,setRom]=useState(1)
    const [dataScore,setScoreData]=useState([])



    const scoreCalulate=(zoneView)=>{
    let score=0
      zoneView.map(item=>{
        console.log("xx",item)
        if(item=='lobby'){
          score=score+50
        }
        if(item=='live'){


          score=score+50
        }
        if(item=='live2'){


          score=score+50
        }
        if(item=='live3'){


          score=score+50
        }
        if(item=='live4'){


          score=score+50
        }
        if(item=='live5'){


          score=score+50
        }
        if(item=='live6'){


          score=score+50
        }
        if(item=='live7'){


          score=score+50
        }
        if(item=='live8'){


          score=score+50
        }
        if(item=='expohall'){
          score=score+50
        }
        
        if(item=='resource'){
          score=score+50
        }
        
        if(item=='zone1'){
          score=score+50
        }
        if(item=='zone2'){
          score=score+50
        }
        if(item=='zone3'){
          score=score+50
        }
        if(item=='zone4'){
          score=score+50
        }
        if(item=='zone5'){
          score=score+50
        }
        if(item=='zone6'){
          score=score+50
        }
        if(item=='zone7'){
          score=score+50
        }
        if(item=='zone8'){
          score=score+50
        }
        if(item=='zone9'){
          score=score+50
        }
        if(item=='zone10'){
          score=score+50
        }
        if(item=='zone11'){
          score=score+50
        }
        if(item=='zone12'){
          score=score+50
        }  if(item=='photobooth'){
          score=score+50
        }
       
        
      
       
      })
      return score
    }

   const  req = async () => {
        let response = await axios.post('https://sirc.virtuallive.in:3002/leaderboardscore', {userEmail: "kunal@gmail.com"})
      
        setScoreData(response.data)
        if(response.status==200)
        {
            setRom(rom+1)
            

        }
      }
    

      useEffect(async() => {
          let timer1 = setTimeout(async() => {
            let response = await axios.post('https://sirc.virtuallive.in:3002/leaderboardscore', {userEmail: "kunal@gmail.com"})
         
          setScoreData(response.data)
          }, 5000);
          
          return () => {
           clearTimeout(timer1);
          };
        },
       
        [dataScore]
      );
    return (
      <>
      <Link to="/lobby">
        <div className="absolute z-max flex  items-center jsutify-center flex-column " style={{top:'26%',left:'10%'}}> 
       <div className="flex">  <img className="h2-3 w2-3" src={BackLobby} alt="" /></div>
       <div className="ttu f8 mt1 fw6 white">back to Lobby</div>
       </div>
       </Link>

     
        <div className="absolute z-max flex pointer  items-center jsutify-center flex-column " style={{top:'26%',left:'85%'}}  onClick={() => setAgendaShow(true)}> 
       <div className="flex">  <img className="h2-3 w2-3" src={Rsgif} alt="" /></div>
       <div className="ttu f8  mt1 fw6 white ttu">T & c</div>
       </div>

      <div
      className={`${window.innerHeight >900 ? 'apibig':window.innerHeight > 700?'apiMedium':'api'}  flex items-center justify-center`}
     
    >

    
          <div className="flex items-center justify-center flex-column w-70 mt3 " 
     >
            <div className="flex mt4 items-center justify-center w-90 ">
              <div className="flex  items-center justify-center  ba b--silver w-15 white fw6 ttu pa1 h2-3">Rank</div>

              <div className="flex  items-center justify-center ba b--silver  w-35  white fw6 ttu pa1 h2-3 ">Name</div>
              <div className="flex  items-center justify-center ba  b--silver w-35  white  fw6 ttu pa1 h2-3">Email</div>
              <div className="flex  items-center justify-center ba  b--silver w-15   white fw6 ttu pa1 h2-3">Points</div>
            </div>



<div className="w-100   flex flex-column items-center justify-start" style={{maxHeight:'400px', height:'300px', overflowY: 'scroll'}}>
           {dataScore&&dataScore.zoneAttend&& dataScore.zoneAttend.map((item,index)=>{return(
           <div className="bl  br bb b--silver flex items-center justify-start h2-3 pa1 w-90 ">
              <div className="flex  items-center justify-center   w-15 white  lh-copy">{index+1}</div>

              <div className="flex  items-center justify-center  mh1 w-35  white ttu f6 lh-copy">{item.userName}</div>
              <div className="flex  items-center justify-center   w-35 white  lh-copy">{item.userEmail}</div>
              <div className="flex  items-center justify-center  mh1 w-15 white  lh-copy">{scoreCalulate(item.zoneView)}</div>
            </div>

           )}) 
            }

</div>
          </div>

      

          {agendaShow && (
        <div className="flex items-center justify-center w-100   ">
          <div
            className="flex w-100   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-60   flex justify-center items-center mh3 br3 ">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex  pa1 flex-column items-center justify-center w-100 ">
                  <div
                    className=" w-100 flex items-end justify-end pointer f6 grey fw6 "
                    onClick={() => setAgendaShow(false)}
                  >
                    X
                  </div>
                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../image/leaders-boardpoint.jpg").default}
                          alt=""
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
        </>
    )
}
