import React, { useState,useEffect } from "react";
import ForgotPassword from "../common/forgot";
import { useLocation } from "react-router-dom";

import { useHistory } from 'react-router-dom';
import Datajson from '../data.json'
import {Redirect} from 'react-router-dom'
import axios from 'axios'
export default function Homepage() {

// console.log("=======>>>>>",Datajson.Sheet2.find({memberId:'123456'}))


//  console.log("======>>>>",dat)
// const result = Datajson.Sheet2.find( ({ name }) => name === 'cherries' );
  // useEffect(() => {
  //   const script = document.createElement('script');
  
  //   script.src = 'https://embed.tawk.to/60dacd3f65b7290ac63871e4/1f9bb3gu7';
  //   script.async = true;
  
  //   document.body.appendChild(script);
  
  //   return () => {
    
  //     document.body.removeChild(script);
  //   }
  // },[]);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameEdit, setFullNameEdit] = useState(true);
  const [loginStatusCode, setLoginStatusCode] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [userName, setUserName] = useState("")
const [memberIdError, setMemberIdError] = useState("")

const [memberId, setMemberId] = useState("")
  const [showPass, setPassShow] = useState('');
  const [loginPass, setLoginPass] = useState("");
  const [loginEmail, setLoginEmail] = useState("");

  const [showConfPass, setConfPassShow] = useState('');
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const history = useHistory();
  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }
  const Phoncheck = /^[0-9\b]+$/;
  var passwordCheck =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  var reEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  function emailCheck() {
    console.log("cvbnm====>>>>>>>>>>", reEmail.test(email));
  }

 
const handleRegistration=async()=>{
let response=await axios.post('https://sirc.virtuallive.in:3002/registration',{
  userName: fullName,
      userEmail: email,
      password:password,
     confirmpassword:cnfPassword
})
console.log("=>>>>>>>>",response)
}
const location = useLocation();

const handleLogin=async()=>{
 

// const dat2=Datajson.Sheet2.find( ({ memid }) => memid === memberId )


const dat2=Datajson.Sheet2.find( ({ memid }) => memid === memberId )




// console.log(dat2)

if(dat2)
{
// alert("find")

localStorage.setItem('userEmail',dat2.email)
  localStorage.setItem('userMemberId',dat2.memid)
  localStorage.setItem('userName',dat2.Name.toLowerCase())
  localStorage.setItem('userPhoneNumber',dat2.number)
 window.location.href="/entry"
}
else{
  setLoginStatusCode(201)
}




let response=await axios.post('http://ec2-3-138-111-128.us-east-2.compute.amazonaws.com:3001/findoneuser',{
  userMemberid: memberId,
 userName:userName,
      
 })
 

console.log("12345678=>>>>>>>>",dat2)
// console.log("=>>>>>>>>",response.data)


if(response.status==200)
{
  localStorage.setItem('userEmail',response.data.userEmail)
  localStorage.setItem('userMemberId',response.data.userMemberid)
  localStorage.setItem('userName',response.data.userName)
  localStorage.setItem('userPhoneNumber',response.data.userPhoneNumber)
  // alert(8)
   window.location.href="/entry"
  // <Redirect to="/lobby" />

  
 
} else{
  console.log(response.status)
  setLoginStatusCode(response.status)
}

}

//  alert(window.innerHeight)
const handleChange = (value) => {
  if(value === '' || Phoncheck.test(value))
  { setMemberId(value)
    
    setMemberIdError("")
  }
   else{setMemberIdError("Enter valid Member Id")
  }
  }
  
  const passwordError =
    "Must contain at least one number, one uppercase and lowercase letter, and Min 8 or more characters";
  const cnfPassError = "Password did not match";
  const emailError = "Enter valid Email Id ";
  const nameError = "Enter Vaild Name";
  // console.log("cvbnm====>>>>>>>>>>",  reEmail.test(email), email !== "");
  return (
    <div className={`pa3 ${window.innerHeight >900 ? 'backgroundLoginBigcss':window.innerHeight > 700?'backgroundLoginMedium':'backgroundLogin' }`} >
      <div className="w-95 flex" style={{visibility:'hidden'}}>
        <div className="flex w-75 bg-re">    </div>
        <div className="flex flex-column w-25 mt5 items-center justify-center">
          <div className="flex w-100 fw6 ">Registration</div>
          <div className="flex w-100 flex-column" >
            <div className="flex flex-column  w-100 mt1 ">
              <div className="flex flex-column w-100">
                <input
                value="sss"

                  // onChange={(e) => setEmail(e.target.value)}
                  className="w-100 mt2 pa1 "
                  type="text"
                  value={email}
                  placeholder="Enter Email id "
                />
                <p className="red fw6 f5 lh-copy">
                  {reEmail.test(email) || email == "" ? "" : emailError}
                </p>
              </div>
              <div className="flex flex-column w-100">
                <input
                 value="sss"
                  // onChange={(e) => (setFullName(e.target.value),setFullNameEdit(false))}
                  className="w-100 mt2 pa1"
                  type="text"
                  placeholder="Enter Your Full Name"
                  required=""
                />
                <p className="red fw6 f5 lh-copy">
                  {fullName ||fullNameEdit  ? "" : nameError}
                </p>
              </div>

              <div className="flex flex-column w-100">
                <input
                 value="sss"
                  // onChange={(e) => setPassword(e.target.value)}
                  className="w-100 99 mt2 pa1"
                  type={`${showPass ? "text" : "password"}`}
                  placeholder="Enter password "
                />
                <p className="red fw6 f5 lh-copy">
                  {passwordCheck.test(password)||password == "" ? "" : passwordError}
                </p>
                {!showPass ? (
                  <i
                    class="fas fa-eye-slash tl mt3  pointer"
                    onClick={() => setPassShow(true)}
                    style={{ position: "fixed", right: "6%" }}
                  ></i>
                ) : (
                  <i
                    class="fas fa-eye tl mt3  pointer"
                    onClick={() => setPassShow(false)}
                    style={{ position: "fixed", right: "6%" }}
                  ></i>
                )}
               
              </div>

              <div className="flex flex-column w-100">
                <input
                 value="sss"
                  // onChange={(e) => setCnfPassword(e.target.value)}
                  className="w-100 mt2 pa1"
                  type={`${showConfPass ? "text" : "password"}`}
                  placeholder="Confirm password "
                />
                <p className="red fw6 f5 lh-copy">
                  {password === cnfPassword ? "" : cnfPassError}
                </p>
                {!showConfPass ? (
                  <i
                    onClick={() => setConfPassShow(true)}
                    class="fas pointer  fa-eye-slash tl mt3 "
                    style={{ position: "fixed", right: "6%" }}
                  ></i>
                ) : (
                  <i
                    onClick={() => setConfPassShow(false)}
                    class="fas pointer  fa-eye tl mt3 "
                    style={{ position: "fixed", right: "6%" }}
                  ></i>
                )}
              </div>
              <div className="w-100 flex items-center justify-center">
                <button
                  style={{
                    opacity:
                      fullName != "" &&
                      reEmail.test(email) &&
                      password === cnfPassword &&
                      passwordCheck.test(password)
                        ? 1
                        : 0.6,
                  }}
                  onClick={() => handleRegistration()}
                  className="w-70 pa2 br2 bg-green mt2  borderZero white pointer"
                >
                  Register
                  <p className="bg-red w-100">{loginEmail}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* {alert(window.innerHeight)} */}
        <iframe

          style={{
            position: 'absolute',
            top:window.innerHeight >900 ? '24%':window.innerHeight > 700?'38%':'43%', 
            left:'4.8%',
            height: '100%',
            width: '26.5%',
           
          }}
          class=" "
          src="https://player.vimeo.com/video/645111135"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>{" "}
        </div>
     {!forgotPassword ? <div 
     
     className={`w-95 flex ${window.innerHeight >900 ? 'mt5':window.innerHeight > 700?'':'nt4' }`}
     >
        <div className="flex w-75 mt6"></div>
        <div className="w-25 mt5">
          {/* <div className="flex w-100 navy fw6 ">Login</div> */}
          <div className="flex w-100 flex-column">
            <div className="flex flex-column  w-100 mt1 ">
              <div className="flex flex-column w-100">
                <input
                   onChange={(e) => setUserName(e.target.value)}
                  className="w-100 mt2 pa1 "
                  type="text"
                  value={userName}
                  // onChange={(e) => {
                  //   handleChange(e.target.value), setLoginEmail('');
                  // }}
                  placeholder="Enter Full Name "
                />
                <p className="red fw6 f5 lh-copy">
                  {(reEmail.test(loginEmail) || loginEmail=="") ? "" : emailError}
                </p>
              </div>
              <div className="flex w-100">
                <input
                 id='name'
                 pattern='[0-9]*'
                 type='tel'
                 onChange={(e) =>(handleChange(e.target.value) ,setLoginStatusCode('9'))}
                  // onChange={(e) => setLoginPass(e.target.value)}
                  className="w-100 mt2 pa1"
                  type="text"
                  value={memberId}
                  placeholder="Enter Membership Id"
                />
                
              </div>
             {memberIdError && <div className="flex f7 mt2   mb2  ttu red fw8 items-center justify-center w-100 pointer">
                {memberIdError}
              </div> }
              <div className="flex"><input type="checkbox" /> <p className="n ml2 mt1">Remember me</p>  </div>
              {/* <div className="flex f7  mt2 mb2  ttu red fw8 items-end justify-end w-100 pointer" onClick={()=>setForgotPassword(true)}>
                forgot password
              </div> */}
               
              

              <div className="w-100 flex items-center justify-center">
                <button
                  onClick={() =>
                    (userName != "" && memberId!=="")
                      ? handleLogin()
                      : null
                  }
                  style={{
                    opacity:
                      (userName != "" && memberId!=="") ? 1 : 0.6,
                  }}
                  className="w-70 pa2 br2 bg-navy mt2  borderZero white pointer"
                >
                  Login
                </button>
              </div>
              <div className="mt2  fw6 flex items-center justify-center red">{loginStatusCode==202&&"Password is wrong"} {loginStatusCode==201&&"Membership Id Not Found"}</div>
            </div>
          </div>
        </div>
      </div>:<ForgotPassword email={loginEmail} />
      }
 
    </div>
  );
}
