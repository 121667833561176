import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Lobby from "../whiteSvg/lobby.svg";

import Virtual from "../image/virtual live logo login white.png";
import PhotoBoothIcon from "../whiteSvg/photo booth.svg";
import MainExpo from "../whiteSvg/EXPO WHITE.svg";
import SpeakerProfile from "../whiteSvg/speakers profile.svg";
import ResourceaCenter from "../image/resource center.png";
import AuditoriumIcon from "../whiteSvg/live.svg";
import agendaIcon from "../whiteSvg/agenda (1).svg";
import surveyIcon from "../image/Survey black.png";

import venuetour from '../whiteSvg/venuetour.svg'

export default function Footer(props) {
  const [stateProfile, setProfile] = useState(false);
  const [stateAgenda, setShowAgneda] = useState(false);

  const menuArr = [
    {
      name: "lobby",
      img: "../whiteSvg/lobby.svg",
    },
    {
      name: "auditorium",
      img: "../image/icon.svg",
    },
    {
      name: "Expo",
      img: "../image/icon.svg",
    },
    {
      name: "Breakout Hall",
      img: "../image/icon.svg",
    },
    {
      name: "photobooth",
      img: "../image/icon.svg",
    },
    {
      name: "photoentry",
      img: "../image/icon.svg",
    },
  ];
  const location = useLocation();
  // console.log("11==",location.pathname);
  return (
    location.pathname !== "/api" &&
    location.pathname !== "/" &&
    location.pathname !== "/leaderboard" && (
      <div
        className="flex items-center   justify-center w-100  "
        style={props.color&&{background:'#140f49',padding:'1rem',
        
        
        }}
       
      >
      
      <div className=" absolute top-0 left-2">
      
            {" "}
            <img style={{height:"50px",paddingTop:window.location.pathname==="/live"?'1rem':'0rem'}} src={Virtual} />
        
        
        </div>
        {stateProfile && (
          <div
            className="flex w-100 ba    justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-50   flex justify-center items-center mh3 br3 overflow-hidden">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex  flex-column items-center justify-center w-100 ">
                  <div  onClick={() => setProfile(false)} className=" bg-navy h2 w-100 white flex items-center justify-center pointer ">
                 

                    <div
                      className="flex w-100 mr2  items-end f6 grey justify-end"
                      onClick={() => setProfile(false)}
                    >
                      X
                    </div>
                  </div>

                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../image/speaker.jpg").default}
                          alt=""
                          style={{ height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {stateAgenda && (
          <div
            className="flex w-100    justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-50   flex justify-center items-center br3 overflow-hidden">
              <div className="w-100  flex  items-center justify-center bg-white">
                <div className="flex   flex-column items-center justify-center w-100 ">
                  <div className=" bg-navy h2 w-100 white flex items-center justify-center pointer ">
                   

                    <div
                      className="flex w-100 mr2  items-end f6 grey justify-end"
                      onClick={() => setShowAgneda(false)}
                    >
                      X
                    </div>
                  </div>

                  <div
                    className=" w-100 flex items-center justify-center "
                    style={{ overflow: "scroll" }}
                  >
                    <div>
                      <div id="myDiv" className="img-wrapper">
                        <img
                          className=""
                          src={require("../image/agendamenu.jpg").default}
                          alt=""
                          style={{ height: "auto"}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}


        <div className="w-10 flex flex-column z-999 items-center justify-center">
          <a href="/lobby">
            {" "}
            <img className="h2" src={Lobby} />
          </a>
          <div className="ttu white  f8 mt1  fw4">Lobby</div>
        </div>

        <div className="w-10 flex flex-column z-999 items-center justify-center">
          <a   href={location.pathname == "/live"? "/live":'auditorium'}>
            {" "}
            <img className="h2" src={AuditoriumIcon} />
          </a>
          <div className="ttu white  f8 mt1  fw4" >auditorium</div>
        </div>


        <div className="w-10 flex flex-column z-999 items-center justify-center">



          <a 
          href={(location.pathname !== "/expo"&& location.pathname !== "/zone1"&& location.pathname !== "/zone2"&&location.pathname !== "/zone3"
          &&location.pathname !== "/zone4"&&location.pathname !== "/zone5"&&location.pathname !== "/zone6"&&location.pathname !== "/zone7"
          &&location.pathname !== "/zone8"&&location.pathname !== "/zone9"&&location.pathname !== "/zone10"&&location.pathname !== "/zone11"
          &&location.pathname !== "/zone12"
          
          )? "/expoentry":'expo'}
          >
         
            <img className="h2" src={MainExpo} />
          </a>
          <div className="ttu white  f8 mt1  fw4">Expo - Hall</div>
        </div>



      
        <div
          onClick={() => setShowAgneda(true)}
          className="w-10 flex flex-column z-999 items-center justify-center"
        >
          <img
            onClick={() => setShowAgneda(true)}
            className="h2"
            src={agendaIcon}
          />

          <div className="ttu white  f8 mt1  fw4">agenda</div>
        </div>

        <div className="w-10  pointer flex flex-column z-999 items-center justify-center">
          <img
            onClick={() => setProfile(true)}
            className="h2"
            src={SpeakerProfile}
          />

          <div className="ttu white  f8 mt1  fw4" onClick={() => setProfile(true)}>Speaker Profile</div>
        </div>
        <div className="w-10 flex flex-column z-999 items-center justify-center">
        <a href="/photoentry">
            {" "}
            <img className="h2" src={PhotoBoothIcon} />
          </a>
          <div className="ttu white  f8 mt1  fw4">PhotoBooth </div>
        </div>


        <div className="w-10 flex flex-column z-999 items-center justify-center">
          <a href="/venuetour">
            {" "}
            <img className="h2" src={venuetour} />
          </a>
          <div className="ttu white  f8 mt1  fw4">Venue Tour</div>
        </div>


        {/* {location.pathname == "/live"&&  <div className="w-10 flex flex-column z-999 items-center justify-center">
        <a   href="https://forms.gle/66L5pjPFsQ7vvxkX6" target="_blank"  >
            {" "}
            <img className="h2" src={surveyIcon} />
          </a>
          <div className="ttu f8  mt1 fw6">Feedback </div>
        </div>
} */}
      </div>
    )
  );
}
