import React from 'react'

export default function Gamesarena() {
    return (
        <div>
            game
            
        </div>
    )
}
