import React, { useState,useEffect } from "react";
import Background from "../image/zone9.jpg";
import StallBg from "../image/zone9.jpg";
import ZOne1Brand from "../Branding/Asia Insurance Review/test.jpg";
// import Pdf from  "../pdf2/asia insurance review/eChina Order Form.pdf";
// import Pdf2 from "../pdf2/asia insurance review/Subscription_Order_form.pdf";
// import Pdf3 from "../pdf/virtual-stall-branding-materials_pdf/KMCH - Hyperarc-A4.pdf";
import axios from 'axios'
import HoverBrand from '../image/tran.png'

// import Pdf4 from "../pdf/virtual-stall-branding-materials_pdf/KMHC_A4.pdf";

import VideoPop from "./videoPop";
import Bcard from "../image/bcard.jpg";
import V1thum from '../image/v91.jpeg'
import V2thum from '../image/v92.jpeg'
import Footer from "./footer";

import html2canvas from "html2canvas";

export default function Zone1() {
  const [show, setShow] = useState(false);

//   useEffect(async() => {
  
//     let response = await axios.post(`https://sirc.virtuallive.in:3002/leaderboard`, {userEmail: localStorage.getItem('userEmail'),zoneView:"zone9",userName: localStorage.getItem('userName')})
 
//     let response2 = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
//  console.log("response",response,response2)
  
  
//   return () => {
  
//   };
// },

// []
// );



const[emailSend,setEmailSend]=useState("")
const sendEmail =async (id) => {

 


  let response2 = await axios.post('https://sirc.virtuallive.in:3002/mailswnd',
  {userEmail: emailSend,
    userEmailBy:localStorage.getItem('userEmail'),
  userName: localStorage.getItem('userName'),
  userMemberid: localStorage.getItem('userMemberId'),
  userPhoneNumber:localStorage.getItem('userPhoneNumber')
})
console.log("response2${req.body.userEmail}${req.body.userEmail}",response2.data)

if(response2.data.data=="done")
{
  alert("your email has sent")
setEmailSend("")
}

}

// 
// after 15000
const [dataScore2,setScoreData2]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/timespentrecord',{userEmail: localStorage.getItem('userEmail'),pageid:"zone9",time:5})
    

//   setScoreData2(dataScore2+1)
//   }, 5000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [dataScore2]
// )


// for last login 
const [lastlogin,setLastlogin]=useState(0)
// useEffect(async() => {
//   let timer1 = setTimeout(async() => {
//     let response = await axios.post('https://sirc.virtuallive.in:3002/lastlogin',
//     {userEmail: localStorage.getItem('userEmail'),
//     userName: localStorage.getItem('userName'),
//     userMemberid: localStorage.getItem('userMemberId'),
//     userPhoneNumber:localStorage.getItem('userPhoneNumber')
//   })
  

// setLastlogin(dataScore2+1)
//   }, 15000);
  
//   return () => {
//    clearTimeout(timer1);
//   };
// },

// [lastlogin]
// )



// useEffect(() => {
//   const script = document.createElement('script');

//   script.src ='https://embed.tawk.to/5f5b4c924704467e89ee1498/default';
//   script.async = true;

//   document.body.appendChild(script);

//   return () => {
    
//     document.body.removeChild(script);
//   }
// },[]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [videoPop, setVideoPop] = useState(false);


  const [imgurl, setImgUrl] = useState();
const [videoUrl, setVideoUrl] = useState("https://player.vimeo.com/video/645476641")
  const [stateMenu, setstateMenu] = useState(false);
  const [share, setShareData] = useState('')
  const [shareEmail, setShareEmail] = useState(true)
  const [stateMenuSurvey, setstateMenuSurvey] = useState(false);
  const [stateMenuWeb, setstateMenuWeb] = useState(false);
  
  
  
  const ShareAsPicture = (id) => {
  
    var html = document.getElementsByTagName('HTML')[0]
    var body =  document.getElementsByTagName('BODY')[0]
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;
    
    var data = document.getElementById(`${id}`)
    var newWidth = data.scrollWidth - data.clientWidth
  
    
    if (newWidth > data.clientWidth){
        htmlWidth += newWidth 
        bodyWidth += newWidth
    }
    console.log("hhhss",htmlWidth,bodyWidth)
    html.style.width = htmlWidth + 'px'
    body.style.width = bodyWidth + 'px'
  
    
    html2canvas(data).then((canvas)=>{
        var image = canvas.toDataURL('image/png', 1);
      
       
        setShareEmail(false)
        setShareData(image)
      
        return image
        
  
    })
  }

  const exportAsPicture = (id) => {
    var html = document.getElementsByTagName("HTML")[0];
    var body = document.getElementsByTagName("BODY")[0];
    var htmlWidth = html.clientWidth;
    var bodyWidth = body.clientWidth;

    var data = document.getElementById(`${id}`);
    var newWidth = data.scrollWidth - data.clientWidth;

    if (newWidth > data.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }
    console.log("hhhss", htmlWidth, bodyWidth);
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    html2canvas(data)
      .then((canvas) => {
        var image = canvas.toDataURL("image/png", 1);
        return image;
      })
      .then((image) => {
        saveAs(image, "vis.png");
        html.style.width = null;
        body.style.width = null;
      });
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    // elem.style = 'height:600px;width:600px';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  return (
    <div
    style={{
      backgroundImage: `url(${StallBg})`,
      backgroundSize: "cover",
      height: "100vh",

      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    }}
  >


{show &&<div className="w-40 absolute"> <A  /> </div>}
    
  
    <img

  className=" absolute  z-max "
  src={HoverBrand}
  alt=""
  style={{top:'30%',
  top:window.innerHeight >900 ? '34%':window.innerHeight > 700?'32%':'25%' ,
left:window.innerHeight >900 ? '62%':window.innerHeight > 700?'63%':'63%' ,
height:window.innerHeight >900 ? '180px':window.innerHeight > 700?'140px':'140px',
width:window.innerHeight >900 ? '250px':window.innerHeight > 700?'200px':'150px'
}}

  onMouseOver={() => setShow(true)}
  onMouseOut={() => (setShow(false))}
/>


    <div
          className="flex items-center   justify-center w-100"
          style={{ position: "absolute" ,top:'90%' }}
        >
         
          <Footer />
        </div> 
    <div
      className="ml4 items-center justify-center"
      style={{
        // backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        height: "100vh",
        width:'85%',
        

        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
        {
          <div
            className=" absolute z-999  pointer"
            style={{ top: "56%", left: "10%" }}
            // onClick={() => setShowProfile(true)}
          >
            {/* <img
            className="h2-3 w2-3"
            src={require("../src/image/prof.png").default}
            alt=""
          /> */}

            <nav className="nav ">
              <ul className="nav__menu" style={{ background: "transparent" }}>
                <li className="nav__menu-item ">
                  <a className>
                    {" "}
                    <img
                      className="h2-3 w2-3"
                      // src={require("../image/prof.png").default}
                      src={require("../image/doc.gif").default}
                      alt=""
                    />
                  </a>
                  {/* <ul
                    className=" bg-navy  bw2 nav__submenu nt3 shadow-4 "
                    style={{ left: "0%" }}
                  >
                    <li className="  black pl3 f5 fw6  flex items-center white justify-start w-100 flex  items-center justify-start">
                      <a href={Pdf} className="white" target="_blank">
                      eChina Order Form
                      </a>
                    </li>
                    <li className="nav__submenu-item black pl3 f5 fw6  white flex items-center justify-start w-100 flex  items-center justify-start ">
                      <a className="white" href={Pdf2} target="_blank">
                      Subscription_Order_form
                      </a>
                    </li>
                   
                  </ul> */}
                </li>
              </ul>
            </nav>
          </div>
        }

       
{/* 
        <div>
          <div 
          onClick={()=>setVideoPop(true)}
            className="tc"
            style={{
              position: "absolute",
              top: "38%",
              left: "45.5%",
              height: "10%",
              width: "12%",
            }}
          >
            <img
              className="pointer h33 w33"
              src={require("../image/play.gif").default}
              alt=""
            />
          </div>
         
        </div> */}
        <div>
          <iframe
            style={{
              position: "absolute",
              top: window.innerHeight >900 ? '0%':window.innerHeight > 700?'-1%':'-1%' ,
              left: window.innerHeight >900 ? '38.5%':window.innerHeight > 700?'40%':'40%',
              height: "100%",
              width: window.innerHeight >900 ? '30%':window.innerHeight > 700?'26%':'26%',
            }}
            class=" "
            src="https://player.vimeo.com/video/694827283"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>{" "}

          {/* <div
            style={{
              position: "absolute",
              top: "40.5%",
              left: "25.5%",
              width: "100%",
              // opacity:1,

              background: "",
              zIndex: 40,
              height: 20,
            }}
            onMouseOver={() => setstateMenu(false)}
            className=""
          ></div> */}

          {/* <div
            style={{
              position: "absolute",
              top: "38.5%",
              left: "15.5%",
              width: "10%",
              opacity: 1,
              height: 104,

              background: "",
              zIndex: 3,
            }}
            onMouseOver={() => setstateMenu(false)}
            className=""
          ></div> */}
          {/* <div
            style={{
              position: "absolute",
              top: "40.5%",
              left: "25.5%",

              zIndex: 3,
            }}
            className=""
          >
           
          </div> */}
         
        </div>

        {false&& videoPop && (
        <VideoPop
        close={setVideoPop}
        videoUrl={'https://player.vimeo.com/video/645477077'}
         img={[
          {imgurl:V1thum,videurl:'https://player.vimeo.com/video/645477077',text:'VID-20191113-WA0012'},
          {imgurl:V2thum,videurl:'https://player.vimeo.com/video/645477137',text:'VID-20191125-WA0000'}]}  />
        )}

       
      </div>





         {/* opo uppp */}

        {false && <div
        onMouseOut={() => setstateMenuSurvey(false)}
        onMouseOver={() => setstateMenuSurvey(true)}
        onClick={() => setShowPopUp(true)}
        className={`
          flex   items-center  justify-start absolute ${
            stateMenuSurvey ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "40%",
          right: 0,
          width: stateMenuSurvey ? "10%" : "4%",
        }}
      >
        <div className="flex pa1 ml2 "onClick={() => setShowPopUp(true)}>
          {" "}
          <img
            className="h2 w2"
            src={require("../image/Businesscard.png").default}
            alt=""
          />
        </div>
        {stateMenuSurvey && (
          <div onClick={() => setShowPopUp(true)} className=" ml1 f8">
            Business card
          </div>
        )}
      </div>}




      <a
          href={"https://www.asiainsurancereview.com/"}
            className="white"
            target="_blank"
          >
      <div
        onMouseOut={() => setstateMenuWeb(false)}
        onMouseOver={() => setstateMenuWeb(true)}
       
        className={`
          flex   items-center  justify-start absolute ${
            stateMenuWeb ? " bg-white br3" : "bg-white br3"
          } black ttu fw6 pointer z-max`}
        style={{
          top: "48%",
          right: 0,
          width: stateMenuWeb ? "10%" : "4%",
        }}
      >
        <div className="flex pa1 ml2 "onClick={() => setShowPopUp(true)}>
          {" "}
          <img
            className="h2 w2"
            src={require("../image/website.png").default}
            alt=""
          />
        </div>
        {stateMenuWeb && (
          <div onClick={() => setShowPopUp(true)} className=" ml1 f8">
           Website
          </div>
        )}
      </div>
      </a>
      { showPopUp && (
          <div
            className="flex w-100 z-max   justify-center shadow-4 items-center fixed absolute--fill z-9999 bg-black-20"
            style={{ backdropFilter: "blur(2px)" }}
          >
            <div className=" w-35   flex flex-column    justify-center items-center mh3 br3 overflow-hidden bg-white">
              <div onClick={()=>setShowPopUp(false)} className=" pointer flex w-100 fw6 items-center justify-end f6 gray fw7 pa2 bg-gold ">
              X
              </div>
              <div className="w-100   flex   flex-column   items-center justify-center " style={{height:'230px'}}>
                <div
                  className="flex  w-100 flex-column items-center justify-center pa2   cardImg"
                  id="exportContainer1"
                >
              {shareEmail ?      <div className="flex  flex-column h5 w-100    ">
                    {/* <div
                  className=" w-100 flex items-end justify-end pointer "
                  onClick={() => setShowPopUp(false)}
                >
                  X
                </div> */}
                    <div className="pa3 flex  flex-column mt2">
                      <div className="w-100  flex  mt4 ">
                        <div className="flex w-100 white f4 flex  flex-column item-center justify-center">
                          {" "}
                          <div className=" flex  f3 tc flex-column item-center justify-center w-100 fw7 mt3">
                            {localStorage.getItem("userName")}
                          </div>
                          <div className="flex  tc flex-column item-center justify-center w-100 fw5 f5  mt2">
                            ICAI
                          </div>{" "}
                        </div>
                        {/* <div className="flex w-20 ">
                      {" "}
                      <img
                        className="h33  "
                        src={require("../image/prof.png").default}
                        alt=""
                      />{" "}
                    </div> */}
                      </div>

                      <div className="flex w-100 tc  flex-column  mt4 white">
                        <div className="white  tc fw7">
                          {" "}
                          {localStorage.getItem("userEmail")}
                        </div>{" "}
                        <div className="white mt2 tc">   {localStorage.getItem("userPhoneNumber")}{" "}</div>{" "}
                      </div>
                    </div>
                  </div>:<div className="flex flex-column items-center justify-center w-100">

<div className="flex items-center justify-center pa1 w-100  white fw6 mt2 f4 mv2">Hi, {localStorage.getItem("userName")} you can share your card</div>
<div className="flex items-center justify-center w-60 ">
  <input type="text" className="w-100 z-max" placeholder="Enter Email Id"
   value={emailSend}
    onChange={(e)=>setEmailSend(e.target.value)} 
    /> </div>
<div  onClick={()=>sendEmail()} className="flex   mv2 items-center justify-center w-50 mv2 bg-green h2 white br2"> Send </div>


</div>}

                </div>
              </div>
              <div
                className="flex w-100  h2 flex items-center justify-center white bg-gold"
          
              >
               {shareEmail ?<div
                  className="mh2  pointer white"
                  onClick={() => exportAsPicture("exportContainer1")}
                >
                  Download
                </div>:<div
                  className="mh2  pointer white"
                  onClick={() => setShareEmail(true)}
                >
                  View Card
                </div>}
                
                {shareEmail &&   <div className="mh2  pointer white" onClick={()=>ShareAsPicture("exportContainer1")}>Share </div>}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}


const A=(props)=> { return( <div className="flex h6 items-end justify-end w-100 bed z-max"> 
<div className="w-40 bg-yellow"><div className="flex justify-end items-end fixed absolute--fill z-9999 bg-black-20 w-40 h6 "
       style={{
          backdropFilter: 'blur(10px)' ,
       left:window.innerHeight >900 ? '20%':window.innerHeight > 700?'20%':'20%' ,
       top:window.innerHeight >900 ? '50%':window.innerHeight > 700?'50%':'30%' 
       }}>
       <img className="z-mx shadow-4  w-100" style={{ height:window.innerHeight >900 ? '600px':window.innerHeight > 700?'600px':'450px'}}src={ZOne1Brand} alt="" />

      </div></div>

</div>)
}